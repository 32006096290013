<template>
    <div>
      <vx-card class="tabulator_card">
        <!-- <vs-row class="mb-3">
          <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start">
            <treeselect
              v-model="selected_column"
              :multiple="true"
              style="z-index:10000"
              :options="treeDataHide"
              placeholder="Hide Column"
            />
          </vs-col>
          <vs-col
            vs-w="3"
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
          >
            <vs-button color="#0044BA" size="small" @click="HideColumn">
              Hide - Unhide Columns
            </vs-button>
          </vs-col>
        </vs-row> -->
        <div ref="table3" id="example-table-theme"></div>
      </vx-card>
    </div>
  </template>
  
  <script>
  import { TabulatorFull as Tabulator } from "tabulator-tables";
  import Treeselect from "@riophae/vue-treeselect";
  import EventBus from "../components/eventbus";
  import moment from "moment";
  export default {
    components: {
      Treeselect,
    },
    props: ["refund_details","ActiveNavBarTab"],
    data(){
      return {
        selected_column: [],
        treeDataHide:[
          // {
          //   id:"company",
          //   label:"Company"
          // },
          // {
          //   id:"education",
          //   label:"Education"
          // },
          {
            id:"level",
            label:"M Level"
          },
          {
            id:"iiml_level",
            label:"U Level"
          },
          {
            id:"course",
            label:"Course"
          },
          // {
          //   id:"batch",
          //   label:"Batch"
          // },
          {
            id:"enrollment_date",
            label:"Enrollment Date"
          },
          {
            id:"next_call",
            label:"Next Call Date"
          },
          {
            id:"last_call",
            label:"Last Call Date"
          },
          {
            id: "sr_next_call",
            label: "SR Next Call Date",
          },
          {
            id: "sr_last_call",
            label: "SR Last Call Date",
          },
          {
            id:"requested_spoc_first_name",
            label:"USP Re-Enrollment Requested By"
          },
          {
            id:"assigned_to_name",
            label:"Enrolled By Spoc"
          },
          {
            id:"spoc_name",
            label:"CM SPOC"
          },
          {
            id:"sr_name",
            label:"SR SPOC"
          },
          // {
          //   id:"city",
          //   label:"City"
          // },
          // {
          //   id:"city_classification",
          //   label:"City Classification"
          // },
          // {
          //   id:"gm_name",
          //   label:"GM"
          // },
          // {
          //   id:"batch_start_date",
          //   label:"Batch Start Date"
          // },
          // {
          //   id:"created_at",
          //   label:"Lead Created Date"
          // },
          // {
          //   id:"actual_cost",
          //   label:"Actual Cost"
          // },
          {
            id:"agreed_cost",
            label:"Agreed Cost"
          },
          // {
          //   id:"discount_amount",
          //   label:"Discount Amount"
          // },
          // {
          //   id:"reason_for_discount",
          //   label:"Comments"
          // },
          // {
          //   id: "approved_by",
          //   label: "Approved By",
          // },
        ],
        login_spoc_id: '',
      }
    },
    mounted(){
      this.login_spoc_id = localStorage.getItem("spoc_id");
      this.getRefundDetails();
      for (let i = 0; i < this.refund_details.length; i++) {
        const element = this.refund_details[i];
        // if (element.enrollment_date != null) {
        //   element.enrollment_date = moment.unix(element.enrollment_date).format("DD-MMM-YYYY");
        // }
        if (element.batch_start_date != null) {
          element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
        }
        if (element.requested_on != null) {
          element.requested_on = moment(element.requested_on).format("DD-MMM-YYYY");
        }
        if (element.created_at != null) {
          element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
        }
        if (element.level1_approved_date != null) {
          element.level1_approved_date = moment(element.level1_approved_date).format("DD-MMM-YYYY");
        }
        if (element.level2_approved_date != null) {
          element.level2_approved_date = moment(element.level2_approved_date).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        let image_path = "";
        if(this.ActiveNavBarTab == "approved"){
          image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        }
        // else if(this.ActiveNavBarTab == "refunded"){
        //   image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        // }
        else if(this.ActiveNavBarTab == "rejected"){
          image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
        }else{
          image_path = "https://img.icons8.com/color/24/000000/error.png"
        }
        Object.assign(element, { approved:  image_path});
        // Object.assign(element, { refunded:  image_path});
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
    },
    watch:{
      refund_details(val){
        this.refund_details = val;
        this.getRefundDetails();
      for (let i = 0; i < this.refund_details.length; i++) {
        const element = this.refund_details[i];
        // if (element.enrollment_date != null) {
        //   element.enrollment_date = moment.unix(element.enrollment_date)
        //     .format("DD-MMM-YYYY");
        // }
        if (element.batch_start_date != null) {
          element.batch_start_date = moment.unix(element.batch_start_date).format("DD-MMM-YYYY");
        }
        if (element.requested_on != null) {
          element.requested_on = moment(element.requested_on).format("DD-MMM-YYYY");
        }
        if (element.created_at != null) {
          element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
        }
        if (element.level1_approved_date != null) {
          element.level1_approved_date = moment(element.level1_approved_date).format("DD-MMM-YYYY");
        }
        if (element.level2_approved_date != null) {
          element.level2_approved_date = moment(element.level2_approved_date).format("DD-MMM-YYYY");
        }
        if (element.last_call !== null) {
          element.last_call = moment
            .unix(element.last_call)
            .format("DD-MMM-YYYY");
        }
        if (element.next_call !== null) {
          element.next_call = moment
            .unix(element.next_call)
            .format("DD-MMM-YYYY");
        }
        let image_path = "";
        if(this.ActiveNavBarTab == "approved"){
          image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        }
        // else if(this.ActiveNavBarTab == "refunded"){
        //   image_path = "https://cdn-icons-png.flaticon.com/128/3699/3699516.png";
        // }
        else if(this.ActiveNavBarTab == "rejected"){
          image_path = "https://cdn-icons-png.flaticon.com/512/5978/5978441.png"
        }else{
          image_path = "https://img.icons8.com/color/24/000000/error.png"
        }
        Object.assign(element, { approved:  image_path});
        // Object.assign(element, { refunded:  image_path});
        Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
      }
      },
      ActiveNavBarTab(val){
        this.ActiveNavBarTab = val;
        console.log("ActiveNavBarTab",this.ActiveNavBarTab);
      }
    },
    methods:{
      getRefundDetails(){
        // let show_approved_col = false
        // let show_refund_col = false
        // if(this.login_spoc_id == 139){
        //   show_approved_col = true
        // }else if(this.login_spoc_id == 1757){
        //   show_refund_col = true
        // }
        let approved_action_col = true
        let level1_col = false
        if(this.login_spoc_id == 139 || this.login_spoc_id == 3154){
          level1_col = true
        }else if(this.login_spoc_id == 1757){
          level1_col = false
        }
        if((this.login_spoc_id == 3267 || this.login_spoc_id == 3280) && this.ActiveNavBarTab == "pending"){
          approved_action_col = false
        }
        this.tabulator = new Tabulator(this.$refs.table3, {
          maxHeight: "80vh",
          data: this.refund_details,
          rowFormatter: (function(row) {
            var evenColor = "#F5F6FA";
            var oddColor = "#FFF";
            row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
            }),
          columns: [
            {
              title: "Can-ID",
              field: "can_id",
              frozen: true,
              sorter: "alphanum",
            },
            {
              title: "Name",
              field: "name",
              frozen: true,
            },
            {
              title: "Email",
              field: "email",
            },
            {
              title: "Course",
              field: "course",
            },
            {
              title: "Agreed Fee",
              field: "agreed_fee",
            },
            {
              title: "Amount Paid",
              field: "amount_paid",
            },
            {
              title: "VR/MR",
              field: "vr_mr",
            },
            {
              title: "Refund Req Amount",
              field: "refund_amount",
            },
            {
              title: "Refunded Amount",
              field: "refunded_amount",
            },
            {
              title: "Refund Req Amount = Amount Paid",
              field: "refund_greaterthan_paid_amount",
            },
            {
              title: "Refund Request Raised By",
              field: "refund_request_raised_by",
            },
            {
              title: "Approved By",
              field: "approved_by",
            },
            {
              title: "Reason For Refund",
              field: "reason_for_refund",
            },
            {
              title: "Account Holder Name",
              field: "account_holder_name",
            },
            {
              title: "Bank Name",
              field: "bank_name",
            },
            {
              title: "Branch Name",
              field: "branch",
            },
            {
              title: "Account Number",
              field: "account_number",
            },
            {
              title: "IFSC Code",
              field: "IFSC_code",
            },
            {
              title: "Mared as default in MF(Yes/No)",
              field: "marked_as_default",
            },
            {
              title: "Credit note raised in Zoho(Yes/No)",
              field: "credit_note_raised_in_zoho",
            },
            {
              title: "Level 1 Approval",
              field: "level1_approval",
            },
            {
              title: "Level 1 Approved Date",
              field: "level1_approved_date",
            },
            {
              title: "Level 2 Approval",
              field: "level2_approval	",
            },
            {
              title: "Level 2 Approved Date",
              field: "level2_approved_date",
            },
            {
              title: "Level 1 Rejection Reason",
              field: "level1_rejection",
              visible: level1_col
            },
            {
              title: "Level 2 Rejection Reason",
              field: "level2_rejection",
            },
            {
              title: "Requested Raised On",
              field: "requested_on",
            },
            {
              title: "Refund Req Date From ATS",
              field: "created_at",
            },
            {
              title: "Refund(Yes/No)",
              field: "refund",
            },
            {
              title: "Approved",
              field: "approved",
              headerSort: false,
              formatter:"image",
              hozAlign:"center",
              formatterParams:{
                height:"25px",
                width:"25px",
              },
              visible: approved_action_col,
              cellClick: this.openEnrollmentApproval,
              // visible: show_approved_col
            },
            // {
            //   title: "Refunded",
            //   field: "refunded",
            //   headerSort: false,
            //   formatter:"image",
            //   hozAlign:"center",
            //   formatterParams:{
            //     height:"25px",
            //     width:"25px",
            //   },
            //   cellClick: this.openEnrollmentApproval,
            //   visible: show_refund_col
            // },
            {
              title: "About",
              field: "info",
              frozen: true,
              headerSort: false,
              formatter:"image",
              hozAlign:"center",
              formatterParams:{
                height:"25px",
                width:"25px",
              },
              cellClick: this.getId,
            },
          ],
        });
      },
      getId(e, cell) {
        console.log(e, cell._cell.row.data.mwb_id);
        this.openBigPopup(cell._cell.row.data.mwb_id);
      },
      openEnrollmentApproval(e,cell) {
        console.log(e, cell._cell.row.data.mwb_id);
        let obj = {
          data : cell._cell.row.data,
          login_spoc_id : this.login_spoc_id
        }
        EventBus.$emit("open-refund-approval-popup", obj);
      },
      HideColumn(){
        this.treeDataHide.forEach((treeData) => {
             this.tabulator.showColumn(treeData.id)
        });
        if(this.selected_column.length > 0){
          this.selected_column.forEach((selected_item)=>{
            this.treeDataHide.forEach((item) =>{
                if(item.id == selected_item){
                  this.tabulator.hideColumn(item.id)
                }
              })
            })
        }
      },
    }
  };
  </script>
  
  <style>
  .tabulator_card {
    border-radius: 15px;
    border: 1px solid rgba(0, 0, 0, 0.25) !important;
  }
  .tabulator {
    background-color: #f2f2f2;
  }
  
  .tabulator .tabulator-header {
    background-color: #f2f2f2;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-row:nth-child(odd) {
    background-color: #f2f2f2;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-row:nth-child(even) {
    background-color: #e4e1e1;
    color: rgb(0, 0, 0) !important;
    /* padding-block: 5px; */
  }
  
  .tabulator .tabulator-header .tabulator-col {
    text-align: center;
  }
  
  .tabulator-calcs-bottom .tabulator-cell {
    background-color: rgb(0, 0, 0);
    color: #f2f2f2 !important;
  } 
  </style>