<template>
    <div>
      <div class="approval_search_container">
        <vs-row>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
            <div class="usp_enrollment_count">
              <p class="enrollment_text">Refunds</p>
              <p class="enrollment_text">{{ enrollment_count }}</p>
            </div>
          </vs-col>
        </vs-row>
        <vs-row class="mb-12">
          <vs-col vs-w="12" class="mt-4">
            <vs-row>
              <vs-col vs-w="2.5">
                    <div style="display: flex">
                        <vs-input label="Can-ID" v-model="identity" />
                        <vs-button color="#6200ee" icon="search" style="margin: 20px 0 0 5px"
                            @click="getRefundsData(ActiveNavBarTab, 1)"></vs-button>
                    </div>
              </vs-col>
              <vs-col vs-w="2.5">
                    <div style="display: flex">
                        <vs-input label="Mobile" v-model="candidate_number" @input="restrictInput" />
                        <vs-button color="#6200ee" icon="search" style="margin: 20px 0 0 5px"
                        @click="getRefundsData(ActiveNavBarTab, 1)">
                        </vs-button>
                    </div>
              </vs-col>
              <vs-col vs-w="2.5">
                    <div style="display: flex">
                        <vs-input label="Email" v-model="candidate_email"/>
                        <vs-button color="#6200ee" icon="search" style="margin: 20px 0 0 5px"
                        @click="getRefundsData(ActiveNavBarTab, 1)">
                        </vs-button>
                    </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-4" vs-w="3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading ml-1">Refund Req from ATS Date Range</label>
                  <date-range-picker ref="picker" :opens="'left'" :locale-data="{
                    firstDay: 1,
                    format: 'DD-MMM-YYYY',
                  }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="refundReqDateRange" class="enrollment_date">
                  </date-range-picker>
                </div>
                
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7">
                <div>
                  <vs-button color="#6200ee" icon="search" style="margin: 12px 0 0 5px"
                        @click="getRefundsData(ActiveNavBarTab, 1)">
                        </vs-button>
                </div>
              </vs-col>
            </vs-row>
          </vs-col>
          <vs-col vs-w="12" class="mt-4 ml-4">
            <vs-row>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-4" vs-w="3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading ml-1">Level 1 Approved Date Range</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                    firstDay: 1,
                    format: 'DD-MMM-YYYY',
                  }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="level1ApprovedDateRange" class="enrollment_date">
                  </date-range-picker>
                </div>
                
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7">
                <div>
                  <vs-button color="#6200ee" icon="search" style="margin: 12px 0 0 5px"
                        @click="getRefundsData(ActiveNavBarTab, 1)">
                        </vs-button>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-4 ml-4" vs-w="3">
                <div style="display: inline-grid; width: 100%; margin-top: -13px">
                  <label class="date_filter_heading ml-1">Level 2 Approved Date Range</label>
                  <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                    firstDay: 1,
                    format: 'DD-MMM-YYYY',
                  }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                    :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                    v-model="level2ApprovedDateRange" class="enrollment_date">
                  </date-range-picker>
                </div>
                
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7">
                <div>
                  <vs-button color="#6200ee" icon="search" style="margin: 12px 0 0 5px"
                        @click="getRefundsData(ActiveNavBarTab, 1)">
                        </vs-button>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7" style="margin-top: 12px">
                <div>
                  <vs-button color="#0044ba" icon="clear" @click="clearData"></vs-button>
                </div>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </div>
      <div>
        <vx-card class="mt-5">
          <div style="max-width: 410px" class="ml-3">
            <vs-navbar text-color="black" color="#ffffff" active-text-color="#FFFFFF" class="approved_navbar"
              v-model="ActiveNavBarTab">
              <vs-navbar-item index="pending">
                <a>Pending</a>
              </vs-navbar-item>
              <vs-navbar-item index="approved">
                <a>Approved</a>
              </vs-navbar-item>
              <!-- <vs-navbar-item v-if="login_spoc_id == 1757" index="refunded">
                <a>Refunded</a>
              </vs-navbar-item> -->
              <vs-navbar-item index="rejected" style="margin-right: 3%">
                <a>Rejected</a>
              </vs-navbar-item>
            </vs-navbar>
          </div>
          <div class="mt-3">
            <div v-if="refund_details.length != 0">
              <RefundsDashboardTabulator :refund_details="refund_details"
                :ActiveNavBarTab="ActiveNavBarTab"></RefundsDashboardTabulator>
            </div>
            <div v-if="refund_details.length == 0" style="color: grey; margin: 5px" class="mt-5">
              <center>NO DATA AVAILABLE</center>
            </div>
          </div>
          <vs-row style="margin-top: 3%">
            <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
              <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
    </div>
  </template>
  
  <script>
  import EventBus from "../components/eventbus";
  import axios from "axios";
  import constants from "../../constants.json";
  // import EventBus from "../components/eventbus";
  import vSelect from "vue-select";
  
  import DateRangePicker from "vue2-daterange-picker";
  import "vue-tree-halower/dist/halower-tree.min.css";
  import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import "vue-select/dist/vue-select.css";
  import RefundsDashboardTabulator from "./RefundsDashboardTabulator.vue";
  import { VTree, VSelectTree } from "vue-tree-halower";
  import Treeselect from "@riophae/vue-treeselect";
  import Datepicker from "vuejs-datepicker";
  import moment from "moment";
  export default {
    components: {
      VTree,
      vSelect,
      DateRangePicker,
      Datepicker,
      VSelectTree,
      Treeselect,
      RefundsDashboardTabulator,
    },
    data() {
      return {
        currentTablePage: 1,
        tablelinks: 0,
        mode_of_study: null,
        filter: "pending",
        candidate_email: "",
        table_data_showing: false,
        ActiveNavBarTab: "pending",
        identity: "",
        candidate_name: "",
        candidate_number: "",
        refundReqDateRange: {
          startDate: null,
          endDate: null,
        },
        level1ApprovedDateRange: {
          startDate: null,
          endDate: null,
        },
        level2ApprovedDateRange: {
          startDate: null,
          endDate: null,
        },
        enrollment_count: 0,
        dates: null,
        refund_details: [],
        login_spoc_id:"",
      };
    },
    watch: {
      dates(val){
        if(val != null){
          this.refundReqDateRange={
            startDate: null,
            endDate: null,
          }
          this.level1ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
          this.level2ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
        }
      },
      refundReqDateRange(val){
        if(val.endDate !=null && val.startDate != null){
          this.dates = null
          this.level1ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
          this.level2ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
        }
      },
      level1ApprovedDateRange(val){
        if(val.endDate !=null && val.startDate != null){
          this.dates = null
          this.refundReqDateRange = {
            startDate: null,
            endDate: null,
          };
          this.level2ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
        }
      },
      level2ApprovedDateRange(val){
        if(val.endDate !=null && val.startDate != null){
          this.dates = null
          this.refundReqDateRange = {
            startDate: null,
            endDate: null,
          };
          this.level1ApprovedDateRange = {
            startDate: null,
            endDate: null,
          };
        }
      },
      ActiveNavBarTab(val) {
        this.getRefundsData(val, 1)
      },
      currentTablePage(val) {
        this.getRefundsData(this.ActiveNavBarTab, val)
      },
    },
    mounted() {
      EventBus.$on("reload-approbval-data", () => {
        this.ActiveNavBarTab = "pending";
        this.getRefundsData('pending', 1)
       });
      this.login_spoc_id = localStorage.getItem("spoc_id");
      this.getRefundsData('pending', 1)
    },
    methods: {
      getRefundsData(filter, page) {
        this.$vs.loading();
        var request_from = null;
        var request_to = null;
        var level1_approved_from = null;
        var level1_approved_to = null;
        var level2_approved_from = null;
        var level2_approved_to = null;
        if (
          this.refundReqDateRange.startDate !== null &&
          this.refundReqDateRange.endDate !== null
        ) {
          request_from = moment(this.refundReqDateRange.startDate).format("YYYY-MM-DD");
          request_to = moment(this.refundReqDateRange.endDate).format("YYYY-MM-DD");
        }
        if (
          this.level1ApprovedDateRange.startDate !== null &&
          this.level1ApprovedDateRange.endDate !== null
        ) {
          level1_approved_from = moment(this.level1ApprovedDateRange.startDate).format("YYYY-MM-DD");
          level1_approved_to = moment(this.level1ApprovedDateRange.endDate).format("YYYY-MM-DD");
        }
        if (
          this.level2ApprovedDateRange.startDate !== null &&
          this.level2ApprovedDateRange.endDate !== null
        ) {
          level2_approved_from = moment(this.level2ApprovedDateRange.startDate).format("YYYY-MM-DD");
          level2_approved_to = moment(this.level2ApprovedDateRange.endDate).format("YYYY-MM-DD");
        }
        let obj = {
          // level1_approval: filter,
          mobile_number: this.candidate_number,
          can_id: this.identity,
          email: this.candidate_email,
          start_date: request_from,
          end_date: request_to,
          level1_approved_start_date: level1_approved_from,
          level1_approved_end_date: level1_approved_to,
          level2_approved_start_date: level2_approved_from,
          level2_approved_end_date: level2_approved_to,
        }

        let url = '';
        if(this.login_spoc_id == 139 || this.login_spoc_id == 3280 || this.login_spoc_id == 3154 || this.login_spoc_id == 3267){
          obj.level1_approval = filter;
          url = `${constants.SERVER_API}getRefundsFromATS?page=${page}`;
        }else if(this.login_spoc_id == 1757){
          obj.level2_approval = filter;
          url = `${constants.SERVER_API}getRefundsAfterApproved?page=${page}`;
        }
         axios.get(url, {
           params: obj,
           headers: {
             Authorization: `Bearer ${localStorage.userAccessToken}`,
           },
         })
           .then((response) => {
             this.enrollment_count = response.data.total;
             this.table_data_showing = true,
             this.refund_details = response.data.data;
             this.tablelinks = response.data.last_page;
             this.currentTablePage = response.data.current_page;
             console.log("ATS Data", response.data)
             this.$vs.loading.close();
           })
           .catch((error) => {
             this.$vs.loading.close();
             this.handleError(error);
           });
      },
      restrictInput() {
            this.candidate_number = this.candidate_number.replace(/[^0-9]/g, '');
        },
      showTreeBox() {
        console.log("clicked");
        return this.treeDataBDE;
      },
      clearData() {
        this.refundReqDateRange = {
          startDate: null,
          endDate: null,
        };
        this.level1ApprovedDateRange = {
          startDate: null,
          endDate: null,
        };
        this.level2ApprovedDateRange = {
          startDate: null,
          endDate: null,
        };
        this.dates = null;
        this.identity = '';
        this.candidate_number = '';
        this.candidate_email = '';
      },
    },
  };
  </script>
  
  <style lang="scss">
  .vs--searchable .vs__dropdown-toggle {
    height: 38px;
  }
  
  .approval_search_container {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    width: 100%;
    //min-height: 394.69px;
  }
  
  .usp_enrollment_count {
    background: #0044ba;
    border-radius: 15px 0px;
    width: 320.62px;
    height: 37.8px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1%;
    align-items: center;
  }
  
  .enrollment_text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }
  
  .none_padding {
    padding: 0px;
  }
  
  .filter-heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
  }
  
  .enrollment-tree-drop#citys>.vs-con-dropdown {
    background: #eae5fa;
    border: 1px solid rgba(46, 13, 146, 0.5);
    width: 90%;
    border-radius: 10px;
    margin-inline: 2%;
  }
  
  .enrollment-tree-drop#cc>.vs-con-dropdown {
    background: #fae1fe;
    border: 1px solid #de56f4;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
  }
  
  .enrollment-tree-drop#spoc>.vs-con-dropdown {
    background: rgba(188, 235, 250, 0.69);
    border: 1px solid #1fabd8;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
  }
  
  .enrollment-tree-drop#sc>.vs-con-dropdown {
    background: #b4edff;
    border: 1px solid #13a0cd;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
  }
  
  .enrollment-tree-drop#course>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 90%;
    margin-inline: 2%;
  }
  
  .enrollment-tree-drop#Visitor>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
  }
  
  .enrollment-tree-drop#level>.vs-con-dropdown {
    background: rgba(255, 194, 194, 0.69);
    border: 1px solid #ec6868;
    border-radius: 10px;
    width: 100%;
    line-height: 35px;
  }
  
  .enrollment-tree-drop#status>.vs-con-dropdown {
    background: #f5e1ce;
    border: 1px solid #f1a964;
    border-radius: 10px;
    width: 100%;
  }
  
  .enrollment-tree-drop#type>.vs-con-dropdown {
    background: #f1ad78;
    border: 1px solid #f38630;
    border-radius: 10px;
    width: 100%;
  }
  
  .enrollment-tree-drop#level_drop>.vs-con-dropdown {
    background: #99eee3;
    border: 1px solid #14d685;
    border-radius: 10px;
    width: 100%;
  }
  
  .enrollment-tree-drop>.vs-con-dropdown {
    width: 100%;
    min-height: 32px;
    border-radius: 10px;
    padding-inline: 5px;
    color: #000000;
    display: flex !important;
    align-items: center;
  }
  
  .approval_options_filter {
    min-height: 351.22px;
    margin: 2px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  
  .approval_options_filter .vx-card__body {
    padding: 5px !important;
  }
  
  .alignment {
    text-align: initial;
  }
  
  .ele-inside-text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    padding-inline-start: 10px;
  }
  
  .ele-inside-text>i {
    color: rgba(0, 0, 0, 0.4);
  }
  
  .a-iconns>.material-icons {
    margin-left: auto;
  }
  
  .a-icon {
    outline: none;
    text-decoration: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  i {
    font-size: 18px;
  }
  
  .a-iconns {
    color: #000;
    font-weight: 500;
    font-size: 14px;
    width: 99%;
    display: flex;
    justify-content: flex-end !important;
  }
  
  .enrollment_date>.reportrange-text {
    background: #ffffff;
    // border: 1px solid #0044ba;
    // box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
    // min-height: 44.47px;
    border-radius: 10px;
  }
  
  .date_filter_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px; 
  }
  
  .enrollment-date-picker>div input {
    background: #ffffff;
    -webkit-padding-start: 9%;
    display: block;
    border-radius: 5px;
    min-height: 44.47px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    border: 1px solid #0044ba;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
    color: #000000 !important;
    border-radius: 10px;
  }
  
  .aplproval_search_criteria {
    width: 100%;
    background: #ffffff;
    min-height: 139px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
  
  .Criteria_heading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  
  .approval-option-side {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  
  .criteria_chip {
    border-radius: 5px;
  }
  
  .criteria_chip .vs-chip--close {
    background: transparent !important;
    color: #000000;
  }
  
  .criteria_chip .vs-chip--text {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
    text-align: center;
    color: #000000;
  }
  
  .approval-input-filter {
    width: 100% !important;
  }
  
  .approval-input-filter.vs-con-input .input-span-placeholder {
    font-size: 14px;
    margin-left: 1%;
  }
  
  .approval-input-filter>.vs-con-input>.vs-inputx {
    height: 40px;
    border: 1px solid #0044ba !important;
    border-radius: 15px;
  }
  
  .vs-dropdown--menu {
    width: 300px;
  }
  
  .desc-border-box3 {
    border: 1px solid #0044ba;
  }
  
  .approved_navbar .vs-con-items {
    display: contents;
  }
  
  .approved_navbar {
    background: #ffffff;
    border: 1px solid #0044ba;
    border-radius: 10px;
    z-index: 1000;
  }
  
  .approved_navbar li.vs-navbar--item a {
    padding: 3px 17px;
    margin-block: 7px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  
  .approved_navbar .vs-navbar--item:hover {
    color: black !important;
  }
  </style>
  